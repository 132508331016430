import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Dashboard from '../views/dashboard.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresLogin: true },
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
      },
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
      },
      {
        path: 'about-us',
        name: 'About',
        component: () => import(/* webpackChunkName: "about-us" */ '../views/About.vue'),
      },
      {
        path: 'contact-us',
        name: 'ContactUs',
        component: () => import(/* webpackChunkName: "contact-us" */ '../views/Contact-us.vue'),
      },
      {
        path: 'tailor-made-safaris',
        name: 'TailorMadeSafaris',
        component: () => import(/* webpackChunkName: "tailor-made-safaris" */ '../views/enquiry.vue'),
      },
      {
        path: 'experiences',
        name: 'Experiences',
        component: () => import(/* webpackChunkName: "experiences" */ '../views/Experiences.vue'),
      },
      {
        path: '/reservations',
        name: 'Reservations',
        component: () => import(/* webpackChunkName: "reservations" */ '../views/Reservations.vue'),
      },
      {
        path: '/safari-packages',
        name: 'SafariPackages',
        component: () => import(/* webpackChunkName: "safari-packages" */ '../views/safari-packages.vue'),
      },
      {
        path: '/destination-packages/:country/:destinationId/:destinationName',
        name: 'DestinationPackages',
        component: () => import(/* webpackChunkName: "destination-packages" */ '../views/Destination-packages.vue'),
      },
      {
        path: '/tour-ideas/:tourId/:tourName',
        name: 'TourIdeas',
        component: () => import(/* webpackChunkName: "tour-ideas" */ '../views/Tour-ideas.vue'),
      },
      {
        path: 'package-detail/:packageTitle/:packageId',
        name: 'PackageDetail',
        component: () => import(/* webpackChunkName: "package detail" */ '../views/package-detail.vue'),
      },
      {
        path: 'request-quote/:packageTitle/:packageId',
        name: 'RequestQuote',
        component: () => import(/* webpackChunkName: "package detail" */ '../views/request-quote.vue'),
      },
      {
        path: '/:packageTitle/:packageId/activities',
        name: 'Activities',
        component: () => import(/* webpackChunkName: "activities" */ '../views/activities.vue'),
      },
      {
        path: '/destinations',
        name: 'Destinations',
        component: () => import(/* webpackChunkName: "destinations" */ '../views/destinations.vue'),
      },
      {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
      },
      {
        path: '/customer-reviews',
        name: 'Reviews',
        component: () => import(/* webpackChunkName: "reviews" */ '../views/Reviews.vue'),
      },
      {
        path: '/payment',
        name: 'Payment',
        component: () => import(/* webpackChunkName: "payment" */ '../views/Payment.vue'),
      },

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },

  //In case the route is anything else
  {
    path: '*',
    redirect: '/dashboard/home'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// router.beforeResolve((to, from, next) => {
//   if (to.name === "Login" && localStorage.getItem('user')) {
//     next({ path: "/" });
//   }
// });

export default router
