<template>
  <header class="text-center">
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <div class="nav-flex">
          <div class="text-left theMobileWidth d-flex align-items-center justify-content-between">
            <div>
              <a
                class="navbar-brand"
                style="cursor: pointer; display: flex; align-items: flex-start;"
                @click="$router.push({path: '/'})"
              >
                <img
                  alt="Ihamba Adventures logo"
                  src="../assets/ihamba_logo.png"
                >
              </a>
            </div>
            <div class="menu">
              <div @click="openNav()">
                <div>
                  <div class="bar1"></div>
                  <div class="bar2"></div>
                  <div class="bar3"></div>
                </div>
              </div>
            </div>

          </div>

          <div class="
              nav-list-two
              d-flex
              align-items-center
              justify-content-center
            ">
            <div style="display: flex; justify-content: flex-end;">
              <ul class="navbar-nav">
                <li
                  class="nav-item"
                  :class="{ active: $route.name == 'Home' }"
                  style="margin:auto;"
                  @click="$router.push({path: '/home'})"
                >
                  <span class="nav-link"><strong> Home</strong></span>
                </li>
                <li
                  class="nav-item"
                  :class="{ active: $route.name == 'SafariPackages' }"
                  style="margin:auto;"
                >
                  <el-dropdown
                    trigger="click"
                    @command="handleCommand"
                  >
                    <span
                      class="el-dropdown-link"
                      :class="{ active: $route.name == 'SafariPackages' }"
                    >
                      <span class="nav-link">
                        <strong>
                          Destinations<i class="el-icon-arrow-down el-icon--right"></i>
                        </strong>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="Uganda">Uganda</el-dropdown-item>
                      <el-dropdown-item command="Rwanda">Rwanda</el-dropdown-item>
                      <el-dropdown-item command="Kenya">Kenya</el-dropdown-item>
                      <el-dropdown-item command="Tanzania">Tanzania</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
                <li
                  class="nav-item"
                  :class="{ active: $route.name == 'TourIdeas' }"
                  style="margin:auto;"
                >
                  <el-dropdown
                    trigger="click"
                    @command="handleTourIdeas"
                  >
                    <span
                      class="el-dropdown-link"
                      :class="{ active: $route.name == 'TourIdeas' }"
                    >
                      <span class="nav-link">
                        <strong>
                          Tour Ideas<i class="el-icon-arrow-down el-icon--right"></i>
                        </strong>
                      </span>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(types, index) in safari_types"
                        :key="index"
                        :command="types"
                      >{{types.title}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
                <li
                  class="nav-item"
                  :class="{ active: $route.name == 'TailorMadeSafaris' }"
                  style="margin:auto;"
                  @click="$router.push({path: '/tailor-made-safaris'})"
                >
                  <span class="nav-link"><strong> Tailor Adventure</strong></span>
                </li>
                <li
                  class="nav-item"
                  :class="{ active: $route.name == 'ContactUs' }"
                  style="margin:auto;"
                  @click="$router.push({path: '/contact-us'})"
                >
                  <span class="nav-link"><strong> Contact us</strong></span>
                </li>
                <li>
                  <a href="http://www.safariably.com/">
                    <button
                      class="btn btn-md"
                      style="background-color: #C1AA7F; color:white; margin-left: 15px;"
                    >Book A Safari Planning Session</button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="">
      <el-dialog
        title="Outer Dialog"
        :visible.sync="outerVisible"
        center
      >
        <div
          slot="footer"
          class="dialog-footer"
        ></div>
      </el-dialog>
    </div>

    <el-drawer
      title="I am the title"
      :visible.sync="sidebarDrawer"
      direction="ltr"
      size="70%"
      :with-header="false"
    >
      <div class="sidebar">
        <div style="cursor: pointer">
          <div style="
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
            ">
            <img
              :src="$store.state.logoImageUrl"
              style="height: 8em"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
        <div class="side-link">
          <ul>
            <li @click="closeNav()">
              {{ $store.state.currentFullName }}
            </li>
            <li @click="closeNav()">
              <router-link to="/home">
                Home
              </router-link>
            </li>
            <li @click="closeNav()">
              <router-link to="/about-us">
                About Us
              </router-link>
            </li>
            <li @click="closeNav()">
              <router-link to="/safari-packages">
                Tour Packages
              </router-link>
            </li>
            <li @click="closeNav()">
              <router-link to="/tailor-made-safaris">
                Tailor you Safaris
              </router-link>
            </li>
            <li @click="closeNav()">
              <router-link to="/experiences">
                Experiences
              </router-link>
            </li>
            <li @click="closeNav()">
              <router-link to="/contact-us">
                Contact Us
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </el-drawer>
  </header>
</template>

<script>
// import SidebarComponent from './sidebar-component.vue';
import jwt_decode from "jwt-decode";
export default {
  // components: {SidebarComponent},
  data() {
    return {
      drawer: false,
      sidebarDrawer: false,
      outerVisible: false,
      innerVisible: false,

      safari_types: [],
    };
  },

  computed: {
    getInitials() {
      return (nameString) => {
        //  format(parseISO(dateString), "do MMMM yyyy");
        var names = nameString.split(" "),
          initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      };
    },
  },

  mounted() {
    this.setCurrentUser();
    this.getSafariTypes();
  },

  methods: {
    handleCommand(country) {
      this.$router.push({ path: `/safari-packages?whereTo=${country}` });
    },
    handleTourIdeas(tour) {
      // alert(tour);
      // alert(JSON.stringify(tour));
      this.$router.push({ path: `/tour-ideas/${tour.id}/${tour.title}` });
    },
    openNav() {
      this.sidebarDrawer = true;
    },

    closeNav() {
      this.sidebarDrawer = false;
    },

    setCurrentUser() {
      // let userToken = JSON.parse(localStorage.getItem("user"));
      // let decoded = jwt_decode(userToken);
      // this.$store.commit("setCurrentUsername", decoded.data.username);
      // this.$store.commit("setCurrentFullName", decoded.data.fullname);
      // this.$store.commit("setCurrentRole", decoded.data.role);
    },
    logout() {
      // remove user from local storage to log user out
      localStorage.removeItem("user");
      this.sidebarDrawer = false;
      location.reload(true);
    },

    async getSafariTypes() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`api/safari_types/get_all`);
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.safari_types = request.data.safari_types;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
nav {
  background-color: #233a2e;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
nav a {
  text-decoration: none;
}

nav li {
  color: white;
  cursor: pointer;
  transition: 0.5s;
  text-transform: uppercase;
  font-size: 0.8em;
}

.active {
  color: #c1aa7f !important;
}

.side-link {
  padding: 10px 30px;
  cursor: pointer;
  transition: 0.5s;
}

.nav-flex {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-list-two {
  list-style: none;
  height: 100%;
  padding: 0px;
}

.nav-flex span:hover {
  color: #c1aa7f;
  transition: 0.5s;
}

.menu {
  display: none;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 2px;
  background-color: #f0f9eb;
  margin: 6px 0;
  border-radius: 3px;
  transition: 0.4s;
}

.sidebar {
  background-color: #ffffff;
  height: 100%;
  padding: 50px 5px;
}

.sidebar ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  text-align: left;
}

.sidebar ul li {
  border-bottom: 1px solid #eeeeee;
  padding-top: 30px;
}

.sidebar ul li a {
  color: green;
}

.sidebar ul li a:hover {
  color: #684613;
}

.navbar-brand img {
  width: 90px;
  margin-top: 50px;
  border-radius: 8px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .menu {
    display: none;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .menu {
    display: none;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .menu {
    display: block;
  }
  .nav-list-two {
    display: none !important;
  }
  .theMobileWidth {
    width: 100% !important;
  }
  .navbar-brand img {
    width: 50px;
    margin-top: 0px;
  }
  .nav-flex {
    height: 30px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .menu {
    display: block;
  }
  .nav-list-two {
    display: none !important;
  }
  .theMobileWidth {
    width: 100% !important;
  }
  .navbar-brand img {
    width: 50px;
    margin-top: 0px;
  }
  .nav-flex {
    height: 30px;
  }
}
</style>

<style>
el-drawer {
  background-color: red;
}
.v-modal {
  z-index: 0 !important;
}
</style>